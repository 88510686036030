<template>
  <div class="content">
    <PageHeader :title="$t('generic-str.menu.verified-number')" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-header">
          <div class="form-row">
            <div class="col-md-6 mb-6">
              <div class="form-group">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('sms.history.lbl-search-id')"
                    v-model="searchValue"
                    aria-label
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-primary btn-icon btn-search-id"
                      type="button"
                      @click="filter"
                    >
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body block-el p-0">
          <div class="responsive-table">
            <table v-if="fetched" class="table">
              <thead class="bg-light">
                <tr>
                  <th scope="col">{{$tc('generic-str.lbl-number', 1)}}</th>
                  <th scope="col">{{$t('generic-str.lbl-cost')}}</th>
                  <th scope="col">{{$t('generic-str.date')}}</th>
                  <th scope="col">Status</th>
                  <th scope="col">{{$t('sms.infos.filters.field.lbl-carrier')}}</th>
                  <th scope="col">{{$t('generic-str.status.lbl-ported')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="checkedNumber in checkNumbers"
                  :key="checkedNumber.id"
                >
                  <td>{{ checkedNumber.number }}</td>
                  <!-- <td v-else>{{checkedNumber.number}}</td> -->
                  <td>R$ {{ checkedNumber.price || currency4D }}</td>
                  <!-- <td>{{ searchResults }}</td> -->
                  <td>{{ checkedNumber.created_at }}</td>
                  <td>
                    <span
                      v-if="checkedNumber.status == 'active'"
                      class="badge badge-success"
                      >{{$t('generic-str.status.lbl-active')}}</span
                    >
                    <span v-else class="badge badge-danger">{{$t('generic-str.status.lbl-inactive')}}</span>
                  </td>
                  <td>{{ checkedNumber.carrier }}</td>
                  <td>
                    <template v-if="checkedNumber.ported">{{$t('generic-str.yes')}}</template>
                    <template v-else>{{$t('generic-str.no')}}</template>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else class="loading-black qt-block-ui" />
            <br />
            <!--<paginate
              v-if="paginate.last_page > 1"
              :page-count="paginate.last_page"
              :click-handler="getByPage"
              :prev-text="'Anterior'"
              :next-text="'Próxima'"
              :container-class="'pagination'"
              :page-link-class="'page-link'"
              :prev-link-class="'page-link'"
              :prev-class="'page-item'"
              :next-link-class="'page-link'"
              :next-class="'page-item'"
              :page-class="'page-item'"
            ></paginate>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LookupService from '@/services/lookup.service';
import PageHeader from '@/components/PageHeader.vue';
// import moment from 'moment';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
  },
  data() {
    return {
      fetched: false,
      checkedNumbers: [],
      dateRange: {},
      status: [{ name: 'Pendente' }, { name: 'Enviado' }],
      formFilter: { status: '', startDate: null, endDate: null },
      toDay: {},
      searchValue: '',
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      pFilter: null,
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      LookupService.getCheckedNumbers({ page }).then(
        (response) => {
          this.fetched = true;
          this.checkedNumbers = response;
          // this.paginate = response.data;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    filter() {
      /* this.formFilter.startDate = this.dateRange[0];
      this.formFilter.endDate = this.dateRange[1];
      this.$root.$emit('sms.export', this.formFilter); */
    },
  },
  computed: {
    checkNumbers() {
      let tempNumbers = this.checkedNumbers;
      if (this.searchValue !== '' && this.searchValue) {
        tempNumbers = tempNumbers.filter((item) => item.number.toUpperCase().includes(this.searchValue.toUpperCase()));
      }
      return tempNumbers;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
</style>
